import { Link } from "gatsby"
import React from "react"
import Nav from "./nav"
import styles from "./header.module.css"

const Header = ({ title }) => (
  <header className={styles.header}>
      <h1>
        <Link to="/">
          {title}
        </Link>
      </h1>
      <Nav/>
  </header>
)

export default Header
