import React from "react"
import LynxSpacer from "../lynx-spacer"
import styles from "./footer.module.css"

export default () => (
  <>
    <LynxSpacer/>
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <div>Copyright 2019 Patrick McLaughlin</div>
        <div>(TODO Some kind of CC license)</div>
      </div>
      <div>
        This site optimized for Firefox, Chrome, Safari, and Links
      </div>
    </footer>
  </>
)
