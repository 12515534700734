import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import "./colors.css"
import "./main.css"

import Header from "./header"
import Footer from "./footer"
import styles from "./layout.module.css"

const QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, className = ''}) => {
  let render = useCallback(data => (
    <>
      <Helmet>
        <style type="text/css">@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);</style>
      </Helmet>
      <Header title={data.site.siteMetadata.title}/>
      <main className={`${styles.main} ${className}`}>
        {children}
      </main>
      <Footer/>
    </>
  ), children)
  return <StaticQuery query={QUERY} render={render}/>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
