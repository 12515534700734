import React from "react"
import { Link } from "gatsby"
import styles from "./header.module.css"

const Page = ({ children, to }) => (
  <li>
    <Link to={to} activeClassName={styles.active}>
      {children}
    </Link>
  </li>
)

const Nav = () => (
  <nav className={styles.navigation}>
    <ul>
      <Page key="posts" to="/">Posts</Page>
      <Page key="about" to="/about">About Me</Page>
    </ul>
  </nav>
)

export default Nav
