import React from "react"
import { Link } from "gatsby"
import styles from "./headline.module.css"

const Title = ({ children, path }) => path ? (
  <h1><Link to={path}>{children}</Link></h1>
) : (
  <h1>{children}</h1>
);

export default ({ title, path, date, iso8601date }) => (
  <div className={styles.headline}>
    <Title path={path}>{title}</Title>
    <time dateTime={iso8601date}>{date}</time>
  </div>
)
