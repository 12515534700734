import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Headline from "../components/headline"

export default (
  {
    data: {
      markdownRemark: {
        frontmatter: {
          title,
          date,
          iso8601date,
          keywords = [],
        },
        html,
      },
    },
  }) => (
  <Layout>
    <SEO title={title} keywords={keywords}/>
    <Headline title={title} date={date} iso8601date={iso8601date}/>
    <article dangerouslySetInnerHTML={{ __html: html }}/>
  </Layout>
);

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        iso8601date: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        keywords
      }
    }
  }
`
